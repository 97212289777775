import styled from "styled-components"
import FlowerBackground from "../../images/flower_bg.jpg"

const Hero = styled.section`
  background: #ef017c;
  background: rgb(149, 52, 173);
  background: linear-gradient(
    0deg,
    rgba(149, 52, 173, 1) 0%,
    rgba(239, 1, 124, 1) 100%
  );

  .inner-container {
    display: flex;
    max-width: 100%;
    padding-left: calc((100% - 1020px) / 2);
    padding-right: 0;
    @media (max-width: 900px) {
      flex-direction: column;
      padding: 50px 20px;
      border-top: 1px solid #fff;
      position: relative;
      z-index: 1;
    }
  }

  h1 {
    color: #fff;
    margin-bottom: 0;
  }
  h2 {
    color: #f9f9f9;
    font-size: 16px;
    font-weight: 400;
  }
  p {
    color: #fff;
  }

  .heroLeft {
    width: 50%;
    padding: 100px 0 40px 20px;
    @media (max-width: 900px) {
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }

  .heroRight {
    width: 50%;
    background-image: url(${props => props.leadImage});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 900px) {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      background-position: left bottom;
      z-index: -1;
      opacity: 0.25;
    }
  }

  .hero-btn-wrapper {
    a {
      background: #fff;
      padding: 5px 10px;
    }
  }
`

const PriceComparisonBlock = styled.section`
  background: #fff;
  padding: 15px 0;
  .inner-container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }
  .price-item {
    font-weight: 200;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    @media (max-width: 550px) {
      flex-direction: row;
      width: 100%;
      margin: 10px 0px;
    }
  }

  .price-item_competitor {
    font-weight: 200;
    font-size: 1.1rem;
  }

  .actual-competitor {
    font-size: 0.8rem;
    line-height: 1rem;
    display: block;

    @media (max-width: 550px) {
      margin-right: 5px;
    }
  }

  .price-highlight {
    font-weight: 600;
    font-size: 1.5rem;
    background: #ef017c;
    background: rgb(149, 52, 173);
    background: linear-gradient(
      0deg,
      rgba(149, 52, 173, 1) 0%,
      rgba(239, 1, 124, 1) 100%
    );
    color: #fff;
    width: 100px;
    height: 100px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 20px;
    border-radius: 50%;

    @media (max-width: 550px) {
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: auto;
      height: auto;
      padding: 8px 20px;
    }

    img {
      width: 100%;
      display: block;

      @media (max-width: 550px) {
        width: 60px;
        margin-right: 5px;
      }
    }
  }
`

const Testimonials = styled.section`
  /*background: #5d76ef;
  background: rgb(149, 52, 173);
  background: linear-gradient(0deg, #5d76ef 0%, #02dfdf 100%);*/
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;

  h3 {
    text-align: center;
  }

  .testimonials-grid {
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .testimonial-item {
    width: calc(33.33% - 20px);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 15px;
      flex-direction: row;
      padding: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 550px) {
      flex-direction: column;
    }

    .testimonial-item-avatar {
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      border-radius: 50%;
      position: relative;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: 0;
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to right, red, orange);
      }

      @media (max-width: 900px) {
        margin-right: 20px;
        margin-bottom: 0;
        align-self: flex-start;
      }

      @media (max-width: 550px) {
        align-self: center;
        margin-bottom: 20px;
        width: 90px;
        height: 90px;
      }
    }

    .testimonial-item-content {
      font-size: 0.9rem;
      @media (max-width: 900px) {
        flex-grow: 1;
      }
      @media (max-width: 550px) {
      }
    }

    p {
      margin-bottom: 0;

      width: 100%;
    }

    .testimonial-item-credit {
      font-weight: 600;
      font-size: 0.8rem;
      text-align: right;
      display: block;
      width: 100%;
      margin-top: 10px;
      line-height: 1rem;
      em {
        font-weight: 200;
        display: block;
      }
    }
  }
`

const Gallery = styled.section`
  background: #fff;
  padding: 50px 0;

  h3 {
    text-align: center;
  }
`

const BeforeAfter = styled.section`
  background: #fff;
  padding: 50px 0;

  .slideBoxGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .slideBox {
    width: calc(33.33% - 20px);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 40px;

    @media (max-width: 900px) {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }

  h3 {
    text-align: center;
  }
`

const Citations = styled.section`
  background: #ef017c;
  padding: 50px 0;
  background: rgb(149, 52, 173);
  background: linear-gradient(
    0deg,
    rgba(149, 52, 173, 1) 0%,
    rgba(239, 1, 124, 1) 100%
  );

  .citation-item {
    display: block;
    margin-bottom: 40px;
    &:hover {
      .citation-headline {
        color: #d9d9d9;
      }
    }
  }

  .citation-logo {
    background: #fff;
    display: inline-block;
    padding: 0 5px;

    img {
      height: 25px;
      max-width: 200px;
      display: block;
    }
  }

  .citation-headline {
    color: #f9f9f9;
    text-transform: uppercase;
    font-size: 1.8rem;
    @media (max-width: 550px) {
      font-size: 1.2rem;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  p {
    color: #fff;
  }
`

const FAQ = styled.section`
  background: #f9f9f9;
  padding: 50px 0;

  h3 {
    text-align: center;
  }
  .faq-item {
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
  }
  .faq-question {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .faq-answer {
    font-size: 0.9rem;
  }
`

export {
  Hero,
  PriceComparisonBlock,
  Testimonials,
  Gallery,
  BeforeAfter,
  Citations,
  FAQ,
}
