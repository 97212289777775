import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Icon from "../../utilities/icons"
import Model from "../../images/lash_lift_hero_model_2.png"
import {
  Hero,
  PriceComparisonBlock,
  Testimonials,
  Gallery,
  BeforeAfter,
  Citations,
  FAQ,
} from "../../components/styles/LandingPageStyles"
import SEO from "../../components/seo"
import ReactCompareImage from "react-compare-image"

//images
import whiteLogo from "../../images/beauty-gems-logo_white.png"
import testimonialUserOne from "../../images/lash-lift/testimonial_person_1.jpg"
import testimonialUserTwo from "../../images/lash-lift/testimonial_person_2.jpg"
import testimonialUserThree from "../../images/lash-lift/testimonial_person_3.jpg"
import client11_before from "../../images/lash-lift/client_11_before.jpg"
import client11_after from "../../images/lash-lift/client_11_after.jpg"
import client10_before from "../../images/lash-lift/client_10_before.jpg"
import client10_after from "../../images/lash-lift/client_10_after.jpg"
import client5_before from "../../images/lash-lift/client_5_before.jpg"
import client5_after from "../../images/lash-lift/client_5_after.jpg"
import client2_before from "../../images/lash-lift/client_2_before.jpg"
import client2_after from "../../images/lash-lift/client_2_after.jpg"
import client21_before from "../../images/lash-lift/client_2.1_before.jpg"
import client21_after from "../../images/lash-lift/client_2.1_after.jpg"
import client12_before from "../../images/lash-lift/client_12_before.jpg"
import client12_after from "../../images/lash-lift/client_12_after.jpg"
import buzzfeed_logo from "../../images/lash-lift/logos/logo-buzzfeed.jpg"
import elle_logo from "../../images/lash-lift/logos/logo-elle.jpg"
import popsugar_logo from "../../images/lash-lift/logos/logo-popsugar.svg"
import glamour_logo from "../../images/lash-lift/logos/logo-glamour.svg"

const LashLiftPage = () => {
  return (
    <Layout>
      <SEO
        title="Lash Lift Newark | Beauty Gems"
        description="Mobile beauty therapist, offering Lash Lift in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
      />
      <Hero leadImage={Model}>
        <div className="inner-container">
          <div className="heroLeft">
            <h1>Lash Lift</h1>
            <h2>Mobile Beautician Newark</h2>
            <p>
              Like old-school perms from the '80s, lash lifts set the shape of
              your natural lashes using a chemical solution. A lift is
              essentially what your lash would be doing with a really good
              mechanical curler, so giving a nice shape upward and a realistic
              curl. Lash lifts typically last for up to 8 weeks. Lash lifts also
              includes a tint.
            </p>
            <p>
              Looking for a lash lift in <b>Newark on Trent?</b> Book with me
              now to see how amazing your eyelashes can look without mascara!
            </p>

            <div className="global-btn-wrapper">
              <Link
                className="global-btn global-btn_white"
                to="/contact"
                state={{ treatment: "Lash Lift" }}
              >
                Book Appointment
              </Link>
            </div>
          </div>
          <div className="heroRight" />
        </div>
      </Hero>

      <PriceComparisonBlock>
        <div className="inner-container">
          <div className="price-item price-item_competitor">
            <span className="actual-competitor">Newark Beauty Rooms</span>
            <span className="actual-price">£45</span>
          </div>

          <div className="price-item price-item_competitor">
            <span className="actual-competitor">New Image</span>
            <span className="actual-price">£45</span>
          </div>

          <div className="price-item price-item_self">
            <div className="price-highlight">
              <img src={whiteLogo} alt="Beauty Gems Logo" />
              <span className="actual-price">£28</span>
            </div>
          </div>

          <div className="price-item price-item_competitor">
            <span className="actual-competitor">Philosophy</span>
            <span className="actual-price">£35</span>
          </div>

          <div className="price-item price-item_competitor">
            <span className="actual-competitor">Beauty &amp; the Brow</span>
            <span className="actual-price">£55</span>
          </div>
        </div>
      </PriceComparisonBlock>

      <Testimonials>
        <div className="inner-container">
          <h3>Lash Lift Testimonials</h3>

          <div className="testimonials-grid">
            <div className="testimonial-item">
              <div
                style={{
                  backgroundImage: `url(${testimonialUserOne})`,
                }}
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  Hey Gemma, thank you so much for the lash lift and tint you
                  did for me two weeks ago!! My lashes look amazing and I keep
                  getting asked if I’ve got fake lashes on 😄. Definitely going
                  to have this done again!! Thanks again ☺️xx{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Isabel Morris <em>(Client)</em>
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div
                style={{
                  backgroundImage: `url(${testimonialUserTwo})`,
                }}
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  Have just had this done, I love it! I never realised I had
                  long eyelashes 😂{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Joy Eveline <em>(Client)</em>
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div
                style={{
                  backgroundImage: `url(${testimonialUserThree})`,
                }}
                className="testimonial-item-avatar"
              />
              <div className="testimonial-item-content">
                <p>
                  <Icon
                    name="top_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />{" "}
                  I've found my lift is a great way to trick people into
                  thinking I'm blessed with Bambi lashes. I have never, ever
                  been complimented on my eyelashes, but since getting a lift,
                  I've had friends and colleagues commenting left and right.{" "}
                  <Icon
                    name="bottom_speech"
                    color="#ef017c"
                    width="12"
                    height="12"
                  />
                </p>
                <span className="testimonial-item-credit">
                  Bella Cacciatore <em>(Glamour Mag)</em>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "40px" }}
            className="global-btn-wrapper global-btn-wrapper_centered"
          >
            <Link
              className="global-btn global-btn_blue"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </Testimonials>

      {/*<Gallery>
        <div className="inner-container">
          <h3>Gallery</h3>
          <p>Gallery Here</p>
        </div>
      </Gallery>*/}

      <BeforeAfter>
        <div className="inner-container">
          <h3>Before &amp; After</h3>
          <p style={{ textAlign: "center" }} className="byline">
            Slide Right for After! ⟶
          </p>
          <div className="slideBoxGrid">
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client11_after}
                rightImage={client11_before}
              />
            </div>
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client10_after}
                rightImage={client10_before}
              />
            </div>
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client5_after}
                rightImage={client5_before}
              />
            </div>
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client2_after}
                rightImage={client2_before}
              />
            </div>
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client12_after}
                rightImage={client12_before}
              />
            </div>
            <div className="slideBox">
              <ReactCompareImage
                sliderPositionPercentage="0.1"
                leftImage={client21_after}
                rightImage={client21_before}
              />
            </div>
          </div>
          <div
            style={{ marginTop: "40px" }}
            className="global-btn-wrapper global-btn-wrapper_centered"
          >
            <Link
              className="global-btn global-btn_blue"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </BeforeAfter>

      <Citations>
        <div className="inner-container">
          <p>
            Still undecided whether to get a <b>Lash Lift</b>? All my clients in
            Newark <b>love</b> their results, but don't just take our word for
            it...
          </p>
          <div className="citation-grid">
            <a
              href="https://www.buzzfeed.com/natalyalobanova/eyelash-lift-before-and-after-pictures-that-are-so-damn"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={buzzfeed_logo} alt="buzzfeed logo" />
              </div>
              <h3 className="citation-headline">
                17 Lash Lift Before And After Pictures That'll Give You Serious
                Goals{"  "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.elle.com/beauty/makeup-skin-care/a19409536/what-is-lash-lift/"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={elle_logo} alt="elle logo" />
              </div>
              <h3 className="citation-headline">
                Everything You Need to Know About Lash Lifts (and Why They're
                Better Than Lash Extensions){" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.popsugar.co.uk/beauty/What-Lash-Lift-Eyelash-Perm-Before--After-Photos-44329698"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={popsugar_logo} alt="pop sugar logo" />
              </div>
              <h3 className="citation-headline">
                I Got an Eyelash Perm, and Holy S**t — the Hype Is So Real{" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
            <a
              href="https://www.glamour.com/story/what-is-a-lash-lift-review"
              target="_blank"
              className="citation-item"
            >
              <div className="citation-logo">
                <img src={glamour_logo} alt="pop sugar logo" />
              </div>
              <h3 className="citation-headline">
                I Got a Lash Lift, and It’s as Life Changing as Everyone Says{" "}
                <Icon
                  name="external_link"
                  color="#ffffff"
                  width="24"
                  height="24"
                />
              </h3>
            </a>
          </div>
          <div className="global-btn-wrapper global-btn-wrapper_centered">
            <Link
              className="global-btn global-btn_white"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </Citations>

      <FAQ>
        <div className="inner-container">
          <h3>FAQ</h3>
          <div className="faq-item">
            <div className="faq-question">
              Are you qualified in doing Lash Lift?
            </div>
            <div className="faq-answer">
              YES! Fully trained and insured with all the relevant
              certifications...
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">How long does it take?</div>
            <div className="faq-answer">It takes around 1 hour 15 minutes.</div>
          </div>
          <div className="faq-item">
            <div className="faq-question">How long does it last?</div>
            <div className="faq-answer">Around 6 - 8 Weeks.</div>
          </div>
          <div className="faq-item">
            <div className="faq-question">Where do you operate?</div>
            <div className="faq-answer">
              Newark and surrounding areas, including Balderton, Claypole,
              Farndon, Muskham, Elston, Collingham, Winthorpe, Coddington, Long
              Bennington! Get in touch if you're in other areas I'm sure we can
              work something out.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">
              Why are you a lot cheaper than a salon?
            </div>
            <div className="faq-answer">
              Simply - Less overheads! As a mobile beautician I come to you, in
              your own home so don't have the expensive salon costs.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">Can you still wear mascara?</div>
            <div className="faq-answer">
              Yes you can, but I'd advise using an oil free make up remover to
              remove the mascara.
            </div>
          </div>
          <div className="faq-item">
            <div className="faq-question">
              Do you tint the eyelashes as well?
            </div>
            <div className="faq-answer">Yes!</div>
          </div>
          <p style={{ textAlign: "center" }}>
            <b>
              Have a question about lash lift? Get in touch to ask me anything!
            </b>
          </p>
          <div
            style={{ marginTop: "40px" }}
            className="global-btn-wrapper global-btn-wrapper_centered"
          >
            <Link
              className="global-btn global-btn_blue"
              to="/contact"
              state={{ treatment: "Lash Lift" }}
            >
              Book Appointment
            </Link>
          </div>
        </div>
      </FAQ>
      <p
        style={{ textAlign: "center", marginTop: "20px", fontSize: "14px" }}
        className="landing-disclaimer"
      >
        Other salon's prices correct as of Sept 2019
      </p>
    </Layout>
  )
}

export default LashLiftPage
